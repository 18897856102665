import _ from 'lodash';
import React, { useContext, useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom'

import Routes, { findRoute} from "../Constants/Routes";

const RoutingContext = React.createContext(null);

const RoutingProvider = ({ children }) => {
    const { pathname } = useLocation();
    const [ currentRoute, setCurrentRoute ] = useState([]);

    useEffect(() => {
        let route = findRoute(pathname);
        setCurrentRoute(route);
    }, [pathname])

    return (
        <RoutingContext.Provider
            value={{
                setCurrentRoute,
                currentRoute
            }}
        >
            {children}
        </RoutingContext.Provider>
    );
};

const useRouting = () => {
    const Event = useContext(RoutingContext);
    if (Event == null) {
        throw new Error("useRouting() called outside of a RoutingProvider?"); // an alert is not placed because this is an error for the developer not the user
    }
    return Event;
};

export { RoutingProvider, useRouting };
