import _ from 'lodash';

export default class AsyncDebounce {
    constructor({ debounceTime = 300} = {}) {
        this.debounceFn = _.debounce((args, functionToCall, resolve, reject) => {
            functionToCall(args)
                .then(resolve)
                .catch(reject)
        }, 300)
    }

    async debounce(args, functionToCall) {
        return new Promise((resolve, reject) => {
            this.debounceFn(args, functionToCall, resolve, reject)
        });
    }
}
