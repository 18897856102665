import _ from 'lodash';
import React, { lazy } from 'react'
import Info from '@material-ui/icons/Info';

import HRTFullLogo from '../Components/SVGIcons/HRTFullLogo'
import HRTHalfLogo from "../Components/SVGIcons/HRTHalfLogo";

const Home = lazy(() => import('../Scenes/Home/Home'))
const Overview = lazy(() => import('../Scenes/Home/Pages/Overview'))

export const Routes = {
    HOME: {
        key: 'HOME',
        title: 'ERI Registry',
        appBarTitle: 'ERI Registry',
        navTitle: 'ERI Registry',
        path: '/',
        Scene: Home,
        // RightIcon: () => (<img style={{ width: 40 }} src={'/BSILogo.png'} />),
        NavIcon: () => (<img style={{ width: 40 }} src={'/BSILogo.png'} />),
        exact: true
    },
    ABOUT: {
        title: 'About',
        path: '/about',
        // RightIcon: () => (<img style={{ width: 40 }} src={'/BSILogo.png'} />),
        NavIcon: (props = {}) => (<Info style={{ fontSize: 30 }} {...props} />),
        Scene: Overview
    }
}

const NavigationRoutes = [
    Routes.HOME,
    Routes.ABOUT
];

export const findRoute = (path) => {
    return _.find(Routes, { path })
}

export default NavigationRoutes
