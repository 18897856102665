import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import {RoutingProvider} from "./Providers/RoutingProvider";
import ApplicationUI from "./Scenes/ApplicationUI";
import Theme from './Constants/Theme'
import {
    BrowserRouter as Router,
} from "react-router-dom";

import {ScreenSizeProvider} from "./Providers/ScreenSizeProvider";
import {HouseProvider} from "./Providers/HouseProvider";

function App() {
  return (
      <ThemeProvider theme={Theme}>
          <Router>
              <RoutingProvider>
                  <ScreenSizeProvider>
                      <HouseProvider>
                          <ApplicationUI />
                      </HouseProvider>
                  </ScreenSizeProvider>
              </RoutingProvider>
          </Router>
      </ThemeProvider>
  );
}
export default App;
